// first palette for Casino True Fortune
$bg-color: #010a0a;
//$text-color: #fff;
$text-color: #dee2e2;
$primary: #00a4a4;
$success: #00A36C;
$info: #1A4E4E;
//$info: #0070A3;
$danger: #A32000;


//hamburger-menu in text-color
$navbar-dark-icon-color: $text-color;


$secondary: mix($bg-color, #fff, 50%);
$input-placeholder-color: $secondary;



#rivalHeaderNavAndLogoSection {
  img {
    margin-top: -4px;
    margin-bottom: -10px;
    max-width: 200px !important;
  }
}

#promos {
  img {
    width: 120% !important;
  }
}


.sticky-sidebar {
  .nav-link:hover {
    color: $primary !important;
    .casino-icon path {
      fill: $primary !important;
    }
  }
  .active-link {
    color: $primary !important;
    .casino-icon {
      opacity: 1;
      path {
        fill: $primary !important;
      }
    }
  }
  //TODO language dropdown stay hover color = primary when opened area-expanded true
  .dropdown-item:active {
    background-color: $primary !important;
  }
}



#category-nav li button {
  &:hover, &.active {
    --bs-nav-pills-link-active-color: #{$bg-color} !important;
  }
}

